
module Api {

    function call(url: string, method: string, data: any, success: Function, error?: Function, complete?: Function) : JQueryXHR {
        // NOTE: quick hax to handle IE 10 äö problems
        url = encodeURI(url);
        return $.ajax(url, {
            data: data, type: method, dataType: "json",
            contentType: 'application/json; charset=utf-8',
            converters: {
                "text json": function (value) {
                    // momentify dates
                    return $.parseJSON(value, true);
                 }
            },
            error: function (qXHR, textStatus, er) {
                if (error !== undefined) {
                    var exception = null;
                    try {
                        exception = JSON.parse(qXHR.responseText);
                    } catch (err) {
                        console.error(qXHR.responseText);
                    }
                    if (qXHR.status == 401) {
                        error("Ei käyttöoikeutta.");
                    } else if (exception != null && exception != undefined) {
                        if (exception["odata.error"]) {
                            var e = exception["odata.error"];
                            console.error(e.innererror.message);
                            console.error(e.innererror.type);
                            error(e.message.value);
                        } else {
                            console.error(exception.ExceptionMessage);
                            console.error(exception.ExceptionType);
                            error(exception.Message);
                        }
                    } else
                        error("Tapahtui virhe");
                }
            },
            success: d => {
                success(d);
            },
            complete: function (qXHR, textStatus) {
                if (complete != null) {
                    complete(qXHR, textStatus);
                }
            }
        });
    }

    function callSafe(url: string, method: string, data: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        // NOTE: quick hax to handle IE 10 äö problems
        url = encodeURI(url);

        var token = $('[name=__RequestVerificationToken]').val();

        return $.ajax(url, {
            data: data,
            type: method,
            dataType: "json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X_CSRF_TOKEN', token);
            },
            contentType: 'application/json; charset=utf-8',
            converters: {
                "text json": function (value) {
                    // momentify dates
                    return $.parseJSON(value, true);
                }
            },
            error: function (qXHR, textStatus, er) {
                if (error !== undefined) {
                    var exception = null;
                    try {
                        exception = JSON.parse(qXHR.responseText);
                    } catch (err) {
                        console.error(qXHR.responseText);
                    }
                    if (qXHR.status == 401) {
                        error("Ei käyttöoikeutta.");
                    } else if (exception != null && exception != undefined) {
                        if (exception["odata.error"]) {
                            var e = exception["odata.error"];
                            console.error(e.innererror.message);
                            console.error(e.innererror.type);
                            error(e.message.value);
                        } else {
                            console.error(exception.ExceptionMessage);
                            console.error(exception.ExceptionType);
                            error(JSON.parse(exception.Message));
                        }
                    } else
                        error("Tapahtui virhe");
                }
            },
            success: d => {
                success(d);
            },
            complete: function (qXHR, textStatus) {
                if (complete != null) {
                    complete(qXHR, textStatus);
                }
            }
        });
    }

    function callWithLanguage(url: string, method: string, data: any, acceptLanguage: any, success: Function, error?: Function,complete?:Function): JQueryXHR {       
        //var h = {
        //    'Accept-Language': acceptLanguage,
        //    'method': method

        //}
        // NOTE: quick hax to handle IE 10 äö problems
        url = encodeURI(url);
        return $.ajax(url, {
            data: data, type: method, dataType: "json",
            contentType: 'application/json; charset=utf-8',
            converters: {
                "text json": function (value) {
                    // momentify dates
                    return $.parseJSON(value, true);
                }
            },
            beforeSend: function (request) {
                request.setRequestHeader('Accept-Language', acceptLanguage);
            },
            //headers: h,
            error: function (qXHR, textStatus, er) {
                if (error !== undefined) {
                    var exception = null;
                    try {
                        exception = JSON.parse(qXHR.responseText);
                    } catch (err) {
                        console.error(qXHR.responseText);
                    }
                    if (qXHR.status == 401) {
                        error("Ei käyttöoikeutta.");
                    } else if (exception != null && exception != undefined) {
                        if (exception["odata.error"]) {
                            var e = exception["odata.error"];
                            console.error(e.innererror.message);
                            console.error(e.innererror.type);
                            error(e.message.value);
                        } else {
                            console.error(exception.ExceptionMessage);
                            console.error(exception.ExceptionType);
                            error(exception.Message);
                        }
                    } else
                        error("Tapahtui virhe");
                }
            },
            success: d => {
                success(d);
            },
            complete: function (qXHR, textStatus) {
                complete(qXHR, textStatus);
            }
        });
    }

    // sames as callWithLanguageSafe, but includes antiforgery token in request header
    function callWithLanguageSafe(url: string, method: string, data: any, acceptLanguage: any, success: Function, error?: Function,complete?:Function): JQueryXHR {       
        //var h = {
        //    'Accept-Language': acceptLanguage,
        //    'method': method

        //}
        // NOTE: quick hax to handle IE 10 äö problems
        url = encodeURI(url);
        var token = $('[name=__RequestVerificationToken]').val();
        return $.ajax(url, {
            data: data, type: method, dataType: "json",
            contentType: 'application/json; charset=utf-8',
            converters: {
                "text json": function (value) {
                    // momentify dates
                    return $.parseJSON(value, true);
                }
            },
            beforeSend: function (request) {
                request.setRequestHeader('Accept-Language', acceptLanguage);
                request.setRequestHeader('X_CSRF_TOKEN', token);
            },
            //headers: h,
            error: function (qXHR, textStatus, er) {
                if (error !== undefined) {
                    var exception = null;
                    try {
                        exception = JSON.parse(qXHR.responseText);
                    } catch (err) {
                        console.error(qXHR.responseText);
                    }
                    if (qXHR.status == 401) {
                        error("Ei käyttöoikeutta.");
                    } else if (exception != null && exception != undefined) {
                        if (exception["odata.error"]) {
                            var e = exception["odata.error"];
                            console.error(e.innererror.message);
                            console.error(e.innererror.type);
                            error(e.message.value);
                        } else {
                            console.error(exception.ExceptionMessage);
                            console.error(exception.ExceptionType);
                            error(exception.Message);
                        }
                    } else
                        error("Tapahtui virhe");
                }
            },
            success: d => {
                success(d);
            },
            complete: function (qXHR, textStatus) {
                complete(qXHR, textStatus);
            }
        });
    }

    export function Get(url: string, success: Function, error?: Function, complete?: Function): JQueryXHR {
        if (url.indexOf('?') > -1) {
            url += "&ts=" + new Date().getTime();
        } else {
            url += "?ts=" + new Date().getTime();
        }
        return call(url, "GET", {}, success, error, complete);
    }
    export function GetWithLanguage(url: string, acceptLanguage: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        if (url.indexOf('?') > -1) {
            url += "&ts=" + new Date().getTime();
        } else {
            url += "?ts=" + new Date().getTime();
        }
        return callWithLanguage(url, "GET", {}, acceptLanguage, success, error, complete);
    }

    export function GetWithObject(url: string, data: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return call(url, "GET", data, success, error, complete);
    }
    export function Post(url: string, data: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return call(url, "POST", data, success, error, complete);
    }
    export function PostSafe(url: string, data: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        if (data !== null && typeof data === 'object') {
            data = JSON.stringify(data);
        }
        return callSafe(url, "POST", data, success, error, complete);
    }

    export function PostWithLanguage(url: string, data: any, acceptLanguage: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return callWithLanguage(url, "POST", data, acceptLanguage, success, error, complete);
    }
    export function PostWithLanguageSafe(url: string, data: any, acceptLanguage: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return callWithLanguageSafe(url, "POST", data, acceptLanguage, success, error, complete);
    }

    export function Put(url: string, data: any, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return call(url, "PUT", data, success, error, complete);
    }
    export function Delete(url: string, success: Function, error?: Function, complete?: Function): JQueryXHR {
        return call(url, "DELETE", {}, success, error, complete);
    }
}